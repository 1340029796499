<template>
  <v-row>
    <v-col cols="12" md="6">
      <h5 v-t="'page.account.transactions.detail.giftcode.code'" class="green400--text text-body-2" />
      <p class="mb-0 text--secondary lh-1-2 text-caption" v-text="transaction.referenceId" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
}
</script>
